import React, { useState } from 'react';
import "./pricing.css";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { pricingData } from "../../pricingData";
import { packageData } from "../../pricingData";

import shape4 from "../../assets/images/hero-shape-4.png";
import shape4Two from "../../assets/images/hero-shape-4.1.png";
import Vector from "../../assets/images/Vector.png";
import pricingBannerImg from "../../assets/images/pricing-banner-img.png";
import secureVector from "../../assets/images/secure-vector.png";
import bestChoise from "../../assets/images/best-choise.png";

const Pricing = () => {
    const [activeIndexes, setActiveIndexes] = useState(pricingData.map(() => true));
    const [isHovered, setIsHovered] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndexes((prev) =>
            prev.map((isActive, i) => (i === index ? !isActive : isActive))
        );
    };

    return (
        <>
            <Helmet>
                <title>Pricing</title>
            </Helmet>
            <section className="tp-hero-area-two pb-0 pt-130 p-relative fix">
                <div className="tp-hero-yellow-shape tp-hero-green-shape d-none d-lg-block"></div>
                <div className="ce-hero-shape d-none d-lg-block">
                    <img src={shape4} alt="shape4" />
                </div>
                <div className="ce-hero-shape-two d-none d-lg-block">
                    <img src={shape4Two} alt="shape4Two" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="tp-hero-section-box tp-hero-section-box-four pb-140">
                                <h3 className="tp-hero-bd-title text-black wow tpfadeUp" data-wow-duration=".3s" data-wow-delay=".6s">Connecting You to
                                    <span className="tp-highlight">
                                        <svg width="266" height="12" viewBox="0 0 266 12" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0L266 12H0V0Z" fill="#FFDC60" />
                                        </svg>
                                        <span>Limitless </span>
                                    </span>
                                    Possibilities!
                                </h3>
                                <p className="wow tpfadeUp" data-wow-duration=".5s" data-wow-delay=".7s">Get started for free and choose the right plan of increasing your revenue</p>
                                <div className="tp-hero-three-button-box d-flex align-items-center wow tpfadeUp" data-wow-duration=".7s" data-wow-delay=".9s">
                                    <Link className="tp-btn mr-55" to="#">Choose Plan</Link>
                                    <div className='offer__banner__box'>
                                        <p>Offer is going on till friday , <span>$84,99</span>/mo</p>
                                        <Link className='offer__anchor' to="#"><img src={Vector} alt="Vector" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-duration=".7s" data-wow-delay=".9s">
                            <div className="tp-hero-right-img-four tp-hero-right-img-four-pricing">
                                <img src={pricingBannerImg} alt="pricingBannerImg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='plan-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="plan-content">
                                <h2>Gives You The Best Plan For Your Websites</h2>
                                <p>We believe in empowering our users with a seamless and feature-rich experience that caters to your unique needs. Our pricing plans are crafted to offer flexibility, scalability, and unbeatable value, ensuring that you get the most out of your investment.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className='select-plan-div'>
                                <p>Select Plan</p>
                                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Billed Monthly</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" data-toggle="pill" data-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Billed yearly <span>-15%</span></button>
                                    </li>
                                </ul>
                                <div className='secure-vector-content'>
                                    <img src={secureVector} alt="secureVector" />
                                    <p>Fast, Secure <br />
                                        and Affordable</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className='pricing-tabs-box'>
                                        <div className="row">
                                            {packageData?.map((data, index) => {
                                                return (
                                                    <div className="col-lg-3" key={index}>
                                                        <div className='pricing-box' onMouseEnter={() => setIsHovered(index)} onMouseLeave={() => setIsHovered(null)}>
                                                            <div className='pricing-best-value'>{data.bestValue}</div>
                                                            <img src={bestChoise} alt="bestChoise" />
                                                            <h3>{data.heading}</h3>
                                                            <h4>{data.freeTrial}</h4>
                                                            <span>${data.price}</span>
                                                            <p className='per-person-trial'>{data.perPersonTrial}</p>

                                                            <div className='pricing-box-details'>
                                                                <Link to="#">Details</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                    <div className='pricing-tabs-box'>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className='pricing-box'>
                                                    <div className='pricing-best-value'>Best Value</div>
                                                    <img src={bestChoise} alt="bestChoise" />
                                                    <h3>Starter</h3>
                                                    <h4>Free Trial</h4>
                                                    <span>$0</span>
                                                    <p className='per-person-trial'>per person billed Monthly</p>

                                                    <div className='pricing-box-details'>
                                                        <Link to="#">Details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className='pricing-box'>
                                                    <div className='pricing-best-value'>Best Value</div>
                                                    <img src={bestChoise} alt="bestChoise" />
                                                    <h3>Starter</h3>
                                                    <h4>Free Trial</h4>
                                                    <span>$0</span>
                                                    <p className='per-person-trial'>per person billed Monthly</p>

                                                    <div className='pricing-box-details'>
                                                        <Link to="#">Details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className='pricing-box'>
                                                    <div className='pricing-best-value'>Best Value</div>
                                                    <img src={bestChoise} alt="bestChoise" />
                                                    <h3>Starter</h3>
                                                    <h4>Free Trial</h4>
                                                    <span>$0</span>
                                                    <p className='per-person-trial'>per person billed Monthly</p>

                                                    <div className='pricing-box-details'>
                                                        <Link to="#">Details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className='pricing-box'>
                                                    <div className='pricing-best-value'>Best Value</div>
                                                    <img src={bestChoise} alt="bestChoise" />
                                                    <h3>Starter</h3>
                                                    <h4>Free Trial</h4>
                                                    <span>$0</span>
                                                    <p className='per-person-trial'>per person billed Monthly</p>

                                                    <div className='pricing-box-details'>
                                                        <Link to="#">Details</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className='packages-details'>
                                <div className="accordion" id="accordionExample">
                                    {pricingData?.map((data, index) => {
                                        const isActive = activeIndexes[index];
                                        return (
                                            <div className="card" key={index}>
                                                <div className="card-header" id={`heading${index}`}>
                                                    <h2 className="mb-0">
                                                        <button
                                                            className="btn btn-link btn-block text-left"
                                                            type="button"
                                                            onClick={() => toggleAccordion(index)}
                                                            aria-expanded={isActive ? "true" : "false"}
                                                            aria-controls={`collapse${index}`}
                                                        >
                                                            {data?.detailsHeading}
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div
                                                    id={`collapse${index}`}
                                                    className={`collapse ${isActive ? "show" : ""}`}
                                                    aria-labelledby={`heading${index}`}
                                                    data-parent="#accordionExample"
                                                >
                                                    <div className="card-body">
                                                        {data?.tableData?.map((table, newindex) => (
                                                            <div className="row" key={newindex}>
                                                                <div className="col-lg-12">
                                                                    <div className="table_rows">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-lg-4">
                                                                                <p style={{ textAlign: "left", padding: "26px 0px" }}>{table?.subHeading}</p>
                                                                            </div>

                                                                            <div className={`col-lg-2 column ${isHovered === index ? 'hovered' : ''}`}>
                                                                                <p className={table.detail1 === "✔" ? "check" : table.detail1 === "✘" ? "cross" : "text"}>{table?.detail1}</p>
                                                                            </div>
                                                                            <div className={`col-lg-2 column ${isHovered === index ? 'hovered' : ''}`}>
                                                                                <p className={table.detail2 === "✔" ? "check" : table.detail2 === "✘" ? "cross" : "text"}>{table?.detail2}</p>
                                                                            </div>
                                                                            <div className={`col-lg-2 column ${isHovered === index ? 'hovered' : ''}`}>
                                                                                <p className={table.detail3 === "✔" ? "check" : table.detail3 === "✘" ? "cross" : "text"}>{table?.detail3}</p>
                                                                            </div>
                                                                            <div className={`col-lg-2 column ${isHovered === index ? 'hovered' : ''}`}>
                                                                                <p className={table.detail4 === "✔" ? "check" : table.detail4 === "✘" ? "cross" : "text"}>{table?.detail4}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className='addOns_main'>
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                Add-Ons
                                            </button>
                                        </h2>
                                        <div className='table_rows'>
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <p style={{ textAlign: "left", padding: "26px 0px" }}>
                                                        API Access
                                                    </p>
                                                </div>
                                                <div className="col-lg-7">
                                                    <p className='addOns-para'>start with a free $25 credit (Then charges apply per 1000 requests starting from $0.08)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='table_rows'>
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <p style={{ textAlign: "left", padding: "26px 0px" }}>
                                                        Custom Features & Integration
                                                    </p>
                                                </div>
                                                <div className="col-lg-7">
                                                    <p className='addOns-para'>Request a Quote</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='table_rows'>
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <p style={{ textAlign: "left", padding: "26px 0px" }}>
                                                        HelpDesk
                                                    </p>
                                                </div>
                                                <div className="col-lg-7">
                                                    <p className='addOns-para'>starts at $52/mo</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='table_rows'>
                                            <div className="row align-items-center">
                                                <div className="col-lg-5">
                                                    <p style={{ textAlign: "left", padding: "26px 0px" }}>
                                                        Custom Reports
                                                    </p>
                                                </div>
                                                <div className="col-lg-7">
                                                    <p className='addOns-para'>Request a Quote</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricing; 