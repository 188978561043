import React from 'react';
import "./Header.css";
import LogoImage from "../../assets/images/logo.png";

import { Link } from 'react-router-dom';
import { FaRegUser } from "react-icons/fa";
import { IoChevronDown } from "react-icons/io5";

const Header = () => {
    return (
        <header className='header'>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link className="navbar-brand" to="/">
                                <img src={LogoImage} alt="logo_image" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                            Product <IoChevronDown size="12px" />
                                        </Link>
                                        <div className="dropdown-menu">
                                            <Link className="dropdown-item" to="#">Action</Link>
                                            <Link className="dropdown-item" to="#">Another action</Link>
                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                            Features <IoChevronDown size="12px" />
                                        </Link>
                                        <div className="dropdown-menu">
                                            <Link className="dropdown-item" to="#">Action</Link>
                                            <Link className="dropdown-item" to="#">Another action</Link>
                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                            Integrations <IoChevronDown size="12px" />
                                        </Link>
                                        <div className="dropdown-menu">
                                            <Link className="dropdown-item" to="#">Action</Link>
                                            <Link className="dropdown-item" to="#">Another action</Link>
                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown">
                                        <Link className="nav-link" to="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                            Resources <IoChevronDown size="12px" />
                                        </Link>
                                        <div className="dropdown-menu">
                                            <Link className="dropdown-item" to="#">Action</Link>
                                            <Link className="dropdown-item" to="#">Another action</Link>
                                            <Link className="dropdown-item" to="#">Something else here</Link>
                                        </div>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/pricing">Pricing</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="#">Company</Link>
                                    </li>
                                </ul>
                                <div className='header__right__content'>
                                    <Link to="#"><FaRegUser size={"16px"} color='#292930' />Login</Link>
                                    <Link to="#" className='orange__btn'>Start Free Trial 👋</Link>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;