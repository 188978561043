import React, { useEffect, useState } from 'react';
import "./FooterStyle.css";
import LogoImage from "../../assets/images/logo.png";

import { FaPaperPlane, FaYoutube, FaTwitter, FaFacebookF, FaSkype, FaAngleDoubleUp } from "react-icons/fa";

import { Link } from 'react-router-dom';

const Footer = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 100) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    return (
        <>
            <footer>
                <div className="tp-footer-area pt-130 pb-30 p-relative">
                    <div className="bp-foooter-shape-1 d-none d-lg-block">
                        <img src="assets/img/footer/hero-shape-5.1.png" alt="" />
                    </div>
                    <div className="bp-foooter-shape-2 d-none d-lg-block">
                        <img src="assets/img/footer/testimonial-shape-5.4.png" alt="" />
                    </div>
                    <div className="container">
                        <div className="row wow tpfadeUp" data-wow-duration=".3s" data-wow-delay=".5s">
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="tp-footer-widget">
                                    <div className="tp-footer-widget__logo mb-30">
                                        <Link to="/"><img src={LogoImage} alt="LogoImage" /></Link>
                                    </div>
                                    <div className="tp-footer-widget__text mb-30">
                                        <p>Looking to enhance your business's approachability? Join CommClan and build meaningful connections with your customers.</p>
                                    </div>
                                    <div className="tp-footer-widget__social-link tp-footer-widget__social-link-2 ">
                                        <Link to="#">
                                            <FaYoutube color="#9E9EA2" size="16px" />
                                        </Link>
                                        <Link to="#">
                                            <FaTwitter color="#9E9EA2" size="16px" />
                                        </Link>
                                        <Link to="#">
                                            <FaFacebookF color="#9E9EA2" size="16px" />
                                        </Link>
                                        <Link to="#">
                                            <FaSkype color="#9E9EA2" size="16px" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-md-6 d-flex justify-content-lg-center">
                                <div className="tp-footer-widget">
                                    <div className="tp-footer-widget__title pb-15">
                                        <h3 className="footer-title text-black">Usefull Links</h3>
                                    </div>
                                    <div className="tp-footer-widget__list">
                                        <ul>
                                            <li>
                                                <Link to="#">Contact us</Link>
                                            </li>
                                            <li>
                                                <Link to="#">How it Works</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Create</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Explore</Link>
                                            </li>
                                            <li>
                                                <Link to="/terms-and-conditions">Terms & Services</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2 col-md-6">
                                <div className="tp-footer-widget pl-20">
                                    <div className="tp-footer-widget__title pb-15">
                                        <h3 className="footer-title text-black">Community</h3>
                                    </div>
                                    <div className="tp-footer-widget__list">
                                        <ul>
                                            <li>
                                                <Link to="#">Help Center</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Partners</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Suggestions</Link>
                                            </li>
                                            <li>
                                                <Link href="blog.html">Blog</Link>
                                            </li>
                                            <li>
                                                <Link to="#">Newsletters</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6">
                                <div className="tp-footer-widget">
                                    <div className="tp-footer-widget__title pb-15">
                                        <h3 className="footer-title text-black">Subscribe Newslatter</h3>
                                    </div>
                                    <div className="tp-footer-widget__text mb-55">
                                        <p>Exerci tation ullamcorper suscipit lobortis nisl aliquip ex ea commodo</p>
                                    </div>
                                    <div className="tp-footer-widget__input tp-input-white">
                                        <form action="#">
                                            <input type="text" placeholder="Enter Mail" />
                                            <button type="submit">
                                                <FaPaperPlane color='#292930' />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tp-copyright-area wow tpfadeUp" data-wow-duration=".5s" data-wow-delay=".7s">
                        <div className="container">
                            <div className="copyright-border pt-40">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-12">
                                        <div className="tp-copyright-left text-lg-start text-start text-md-center">
                                            <p className="text-black"> © Copyright ©2025 CommClan. All Rights Reserved</p>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-12">
                                        <div className="tp-copyright-right tp-copyright-color">
                                            <Link to="/terms-and-conditions">Terms and conditions</Link>
                                            <Link to="/privacy-policy"> Privacy policy</Link>
                                            <Link to="#">Login / Signup</Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className='copyright_para'>
                                            <p>Customer service software that adapts to your business needs. Whether you’re a
                                                B2B SaaS or an eCommerce, ConvoBird will help you boost your support and sales
                                                across multiple communication channels.Hi! We are glad to have you on our
                                                website! Before you start using our website, please note that we use cookies and
                                                similar technologies to enhance your website experience, analyze traffic and
                                                usage, personalize content to your preferences, and assist in our marketing
                                                efforts. By using our website, you consent to allow us, and our partners, to
                                                collect, use, retrieve, and store cookies and similar technologies on your
                                                computer or other devices. You can revoke your consent at any time in your
                                                computer/device browser settings. Click the Cookies Policy to check how you can
                                                control the use of cookies through your device. Your data will be processed in
                                                accordance with our Privacy Policy.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {isVisible && (
                <button
                    className={`scroll-top ${isVisible ? 'fade-up' : ''}`}
                    onClick={scrollToTop}
                    data-target="html"
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        backgroundColor: 'var(--yellow-color)',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '6px',
                        padding: '15px',
                        cursor: 'pointer',
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"
                    }}
                >
                    <FaAngleDoubleUp color="#fff" size="18px" />
                </button >
            )}
        </>
    )
}

export default Footer; 