export const pricingData = [
    {
        detailsHeading: "General Features",
        tableData: [
            {
                subHeading: "Manage Team (Agents)",
                detail1: "2 Users",
                detail2: "Up-to 5",
                detail3: "Up-to 15",
                detail4: "Custom",
            },
            {
                subHeading: "View conversation and  history",
                detail1: "✔",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Export conversations",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Email",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "SMS",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Voice Messages",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Ticketing System",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Virtual Agents (custom Chat BOT)",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Multilingual support",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Agents Chat limit",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Smart & Manual Chat Routing",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Chat Tags",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Multiple website support",
                detail1: "✘",
                detail2: "Up-to 2 Websites",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Channels and Threads",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Work Scheduler",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
        ]
    },
    {
        detailsHeading: "Insights & Analytics",
        tableData: [
            {
                subHeading: "Manage user roles & permissions",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Monitor/control user activity & content",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Routing rules based on geolocation",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Notifications and Alerts",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Integrated customer support & helpdesk features.",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Custom Chat Widgets for Each Websites",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Usage statistics and activity logs",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Top searched keywords",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Agent Performance & Activity",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Chat Dashboard for insights and decision-making.",
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Performance and engagement metrics.",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Custom Business Rules (triggers & automations)",
                detail1: "✘",
                detail2: "Up-to 3",
                detail3: "Up-to 7",
                detail4: "Custom",
            }
        ]
    },
    {
        detailsHeading: "Integrations and add-ons",
        tableData: [
            {
                subHeading: `Marketplace connect with 
                third-party apps 
                (leading industry standards)
                HubSpot, Zoho, Pipedrive
                Salesforce,  Zapier, Custom CRM,Calendly, Shopify integration,
                WooCommerce, WIX,
                BigCommerce, Joomla, 
                Chrome Extension, Project Management Tools
                and social media platforms 
                (Facebook Messenger, WhatsApp Instagram).`,
                detail1: "✘",
                detail2: "✘",
                detail3: "✔",
                detail4: "✔",
            }
        ]
    },
    {
        detailsHeading: "Security",
        tableData: [
            {
                subHeading: "256-bit SSL secure connection",
                detail1: "✔",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Banned visitors list",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Credit card masking",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Data center in US or EU",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Two-Factor-Authentication (2FA)",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Access restriction",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Two-Factor-Authentication (2FA)",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: `Data loss prevention (DLP) 
                and compliance with industry standards
                (e.g., GDPR, HIPAA).`,
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Single Sign-On (SSO) ready",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
            {
                subHeading: "Trusted domains",
                detail1: "✘",
                detail2: "✔",
                detail3: "✔",
                detail4: "✔",
            },
        ]
    },
];

export const packageData = [
    {
        bestValue: "Best Value",
        heading: "Starter",
        freeTrial: "Free Trial",
        price: 0,
        perPersonTrial: "per person billed Monthly",
    },
    {
        bestValue: "Best Value",
        heading: "Support",
        freeTrial: "For Small Teams",
        price: 69.00,
        perPersonTrial: "per person billed Monthly",
    },
    {
        bestValue: "Best Value",
        heading: "Pro Business",
        freeTrial: "For Mediam Teams",
        price: 89.00,
        perPersonTrial: "per person billed Monthly",
    },
    {
        bestValue: "Best Value",
        heading: "Suite Enterprise",
        freeTrial: " for larger businesses, agencies",
        price: 159.00,
        perPersonTrial: "per person billed Monthly",
    }
];