import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// import components
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

// import pages
import Home from './pages/home';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import Pricing from './pages/pricing';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [location.pathname]);

  return (
    <>
      <Header />
      <Routes>
        <Route path='*' element={<Home />} />
        <Route path='/terms-and-conditions' element={<Terms />} />
        <Route path='/privacy-policy' element={<Privacy />} />
        <Route path='/pricing' element={<Pricing />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App;