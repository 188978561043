import React from 'react';
import "./privacy.css";
import { Helmet } from 'react-helmet-async';

import headingVector from "../../assets/images/privacyVector.png";
import shape4 from "../../assets/images/hero-shape-4.png";

const Privacy = () => {
    return (
        <>
            <Helmet>
                <title>Privacy Policy</title>
            </Helmet>

            <section className='terms-banner'>
                <div className='terms-banner-before'><img src={shape4} alt="shape4" /></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='terms-banner-content'>
                                <h1>Privacy Policy <span><img src={headingVector} alt="headingVector" /></span></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='terms-content-sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className='terms-content-main'>
                                <h3>1. Introduction</h3>
                                <p>Welcome to CommClan. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines the types of information we collect, how we use it, and the measures we take to protect it.</p>
                                <h3>2. Information We Collect</h3>
                                <p>We collect the following types of information from users of our chat application:</p>
                                <p><b>a. Personal Information:</b></p>
                                <p>Registration Information: When you register, we may collect your name, email address, phone number, and other contact details.
                                    Profile Information: You may choose to provide additional information such as a profile picture, status messages, and other personal details.</p>
                                <p><b>b. Communication Data:</b></p>
                                <p>Messages: We collect and store the messages you send and receive through our chat application.
                                    Media Files: Photos, videos, and other media files you share in chats are also collected and stored.</p>
                                <p><b>c. Usage Data:</b></p>
                                <p>Log Data: We collect information about your use of the chat application, including IP addresses, browser types, operating systems, and usage patterns.</p>
                                <p><b>d. Device Information:</b></p>
                                <p>Device Details: We may collect information about the device you use to access our chat application, including device type, operating system, and unique device identifiers.</p>
                                <h3>3. How We Use Your Information</h3>
                                <p>We use the collected information for the following purposes:</p>
                                <ul>
                                    <li>Providing Services: To provide, maintain, and improve our chat application and services.</li>
                                    <li>Personalization: To personalize your experience and show content that is more relevant to you.</li>
                                    <li>Communication: To communicate with you, including sending service-related announcements and updates.</li>
                                    <li>Security: To enhance the security of our chat application and protect against unauthorized access or use.</li>
                                    <li>Analytics: To analyze usage trends and gather insights to improve our chat application.</li>
                                </ul>

                                <h3>4. Sharing Your Information</h3>
                                <p>We do not sell or rent your personal information to third parties. We may share your information with:</p>
                                <ul>
                                    <li>Service Providers: Trusted third-party service providers who assist us in operating our chat application and providing services.</li>
                                    <li>Legal Requirements: Law enforcement or other governmental agencies when required by law or to protect our legal rights.</li>
                                    <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred.</li>
                                </ul>
                                <h3>5. Data Security</h3>
                                <p>We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
                                <h3>6. Data Retention</h3>
                                <p>We retain your personal information for as long as necessary to provide our services and fulfill the purposes outlined in this Privacy Policy. We may also retain and use your information to comply with legal obligations, resolve disputes, and enforce our agreements.</p>
                                <h3>7. Your Rights</h3>
                                <p>You have the right to:</p>
                                <ul>
                                    <li>Access: Request access to the personal information we hold about you.</li>
                                    <li>Correction: Request correction of any inaccurate or incomplete information.</li>
                                    <li>Deletion: Request deletion of your personal information, subject to certain legal obligations.</li>
                                    <li>Restriction: Request restriction of processing your personal information under certain circumstances.</li>
                                    <li>Objection: Object to the processing of your personal information for certain purposes.</li>
                                </ul>
                                <p>To exercise these rights, please contact us at <a href="mailto:contact@CommClan.com">contact@CommClan.com</a></p>
                                <h3>8. Changes to This Privacy Policy</h3>
                                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the effective date. Your continued use of our chat application after the changes take effect constitutes your acceptance of the revised Privacy Policy.</p>
                                <h3>9. Contact Us</h3>
                                <p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

                                <ul>
                                    <li>Address: ABC address</li>
                                    <li>Email Address: <a href="mailto:contact@CommClan.com">contact@CommClan.com</a></li>
                                    <li>Phone Number: <a href="tel:18774265">1 877 4265</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Privacy;